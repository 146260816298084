import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import {
  BUTTON_STYLE,
  FONT_SIZE,
  BREAKPOINT,
  COLOR
} from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { StickyCta } from '@latitude/sticky-cta';
import { Heading3, Heading4 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { Tel } from '@latitude/tel';
import { Text } from '@latitude/text';
import { Metadata } from '@latitude/metadata';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { HorizontalRule } from '@latitude/horizontal-rule';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { NotificationBanner } from '@latitude/banner';
import PageData from '../data/pages/brokers.json';
import { resetToDefault } from '../components/EstimateRateWidgetSection/utils';
import Lframe from '../components/Lframe/Lframe';
import LoanCardBranded from '../components/LoanCard/LoanCardBranded';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import { SOFT_QUOTE_BROKER_URL } from '../utils/softQuoteUtil';
import Layout from '../components/layout';
import SingleTextBox from '@latitude/single-text-box';
import { ALIGN, MARGIN, PADDING } from '../utils/constants';
import { handleSmoothScroll } from '@/utils/helpers';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const Brokers = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  /** - END - */

  const scrollHandler = event => {
    event.preventDefault();
    handleSmoothScroll(PageData.getAccreditedHashURL, 56);
  };

  const scrollHanlerLoans = event => {
    event.preventDefault();
    handleSmoothScroll('#our-latitude-loans', 80);
  };
  resetToDefault(true);

  return (
    <Layout location={props.location} noHeader brokerHeader hasStickyNav>
      <main className="navigation-spacer navigation-spacer--hasStickyNav bg-f8f8f8">
        <Box backgroundColor={COLOR.GREY6}>
          <Metadata
            title={PageData.metaTitle}
            description={PageData.metaDesc}
            keywords={PageData.metaKeywords}
            canonical={PageData.path}
          />
          <Lframe brokers />
          {!selectDataSourceForAlertBanner(contentfulPageData) && (
            <NotificationBanner siteName="gemfinance-co-nz" />
          )}
          <Hero
            title={PageData.content.hero.title}
            text={PageData.content.hero.text}
            footnote={[
              <Box
                css={`
                  display: inline-block;
                  font-weight: 400;
                  margin-top: -10px;
                  width: 188px;
                `}
              >
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href="javascript:;"
                  onClick={scrollHandler}
                  trackId="hero-get-accredited"
                >
                  Get Accredited
                </Link>
                <p className="pt-1 pb-1">or</p>
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href="javascript:;"
                  onClick={scrollHanlerLoans}
                >
                  Submit Application<sup>*</sup>
                </Link>
              </Box>,
              <Text fontSize={FONT_SIZE.SMALL}>
                <sup>*</sup>Accredited broker use only
              </Text>
            ]}
          />

          <div className="d-lg-none d-xl-none">
            <StickyCta
              href={SOFT_QUOTE_BROKER_URL}
              trackId="sticky-cta-mobile--get-rate-estimate"
              phoneNumber={PageData.phoneNumber}
              text="Get rate estimate"
              ctaType={BUTTON_STYLE.PRIMARY}
              onClick={scrollHandler}
              isMobile
            />
          </div>
          <div className="d-none d-lg-block">
            <StickyNavigation
              items={PageData.nav}
              phoneNumber={PageData.phoneNumber}
              ctaHref={SOFT_QUOTE_BROKER_URL}
              trackId="sticky-navigation--get-rate-estimate"
              offsetElem="[data-sticky-navigation-offset]"
              ctaText="Get rate estimate"
              ctaType={BUTTON_STYLE.PRIMARY}
              onClick={scrollHandler}
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                label: 'Get rate estimate',
                location: 'Sticky Navigation'
              }}
            />
          </div>
          <StyledFeaturesSlider
            heading="Why choose us?"
            id="why-us"
            className="why-choose"
            data={PageData.content.whyChooseUs}
          />
          <HorizontalRule />

          <AnalyticsLocationProvider location="Get accredited">
            <Box
              id={PageData.getAccreditedHashURL.replace('#', '')}
              paddingTop={PADDING.P48}
              css={`
                [class^='Spacing__StyledResponsive'] {
                  margin: auto;
                  max-width: 445px; // custom width override as per UX requirement
                }
              `}
            >
              <SingleTextBox
                box1={
                  <>
                    <Heading4
                      marginBottom={MARGIN.M16}
                      align={ALIGN.LEFT}
                      color={COLOR.BLACK}
                    >
                      Get accredited
                    </Heading4>
                    <Text marginBottom={MARGIN.M16}>
                      Become an accredited Broker with Latitude today.
                    </Text>
                    <Box width="250px" margin={MARGIN.MAUTO}>
                      <Link
                        button={BUTTON_STYLE.PRIMARY}
                        href={PageData.getAccreditedBrokerURL}
                        trackId="get-accredited-broker"
                      >
                        I am a Broker
                      </Link>
                      <Text
                        marginTop={MARGIN.M8}
                        marginBottom={MARGIN.M8}
                        align={ALIGN.CENTER}
                      >
                        or
                      </Text>
                      <Link
                        button={BUTTON_STYLE.PRIMARY}
                        href={PageData.getAccreditedAggregatorURL}
                        trackId="get-accredited-aggregator"
                      >
                        I am an Aggregator
                      </Link>
                      <Text
                        align={ALIGN.CENTER}
                        fontSize={FONT_SIZE.SMALL}
                        css={`
                          margin-top: 5px;
                          line-height: 20px;
                        `}
                      >
                        login to review pending accreditation requests
                      </Text>
                    </Box>
                  </>
                }
              />
            </Box>
          </AnalyticsLocationProvider>

          <AnalyticsLocationProvider location="Our Gem loans">
            <Box.Section id="our-latitude-loans" backgroundColor={COLOR.GREY6}>
              <Heading3
                css={`
                  font-size: 24px;
                  color: ${COLOR.BLACK};
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    font-size: 32px;
                  }
                `}
              >
                Our Gem loans
              </Heading3>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-sm-8 col-md-7 col-lg-12">
                  <CardGroup>
                    <CardGroupItem col={2}>
                      <LoanCardBranded
                        icon="icon-personal-loan"
                        isBranded
                        className="h-100"
                        title="Personal Loan"
                        description={
                          <>
                            Easily check your client&apos;s Personal Loan
                            interest rate and then submit an application
                          </>
                        }
                        rateBoxType="personalSym"
                        rateBoxType2="personalUnsecuredSym"
                        rateBoxFullWidth
                        rateBoxSize="large"
                        listItems1Title={
                          <h4 className="loan-card__list-title">
                            About the loan:
                          </h4>
                        }
                        listItems1={[
                          'Loan amounts from $2,000',
                          'Loan terms from 1 to 7 years',
                          'Fixed and Variable rate loan options',
                          'Weekly, fortnightly or monthly repayments',
                          'No early repayment fees'
                        ]}
                        listItems2Title={
                          <h4 className="loan-card__list-title">
                            Eligibility:
                          </h4>
                        }
                        listItems2={[
                          'Be 18 years or over',
                          'Be a permanent NZ resident',
                          'Be earning a stable income'
                        ]}
                        listItems3Title={
                          <h4 className="loan-card__list-title">Fees:</h4>
                        }
                        listItems3={[
                          'Establishment fee of $240 applies',
                          'Late payment fee - $35',
                          'No early repayment fees',
                          'Your interest rate depends on your personal circumstances'
                        ]}
                        button2={{
                          href: 'https://broker.portal.gemfinance.co.nz/',
                          trackId: 'broker-personal-loan-get-rate-estimate',
                          text: 'Get rate estimate',
                          id: 'estimate-button',
                          trackEventData: {
                            category: 'cta',
                            action: 'quote-link',
                            label: 'Get rate estimate',
                            location: 'Personal Loan'
                          }
                        }}
                      />
                    </CardGroupItem>
                  </CardGroup>
                </div>
              </div>
            </Box.Section>
          </AnalyticsLocationProvider>

          <BrandedCallout
            line1={
              <React.Fragment>
                <strong>
                  Got questions for our broker team? Call now on{' '}
                  <Tel
                    no="0800 433 406"
                    color={COLOR.WHITE}
                    data-trackid="broker-contact-us-phone"
                  />
                </strong>
              </React.Fragment>
            }
            line2={
              <React.Fragment>
                <span>Mon to Fri: 9am - 5:30pm (NZST)</span>
              </React.Fragment>
            }
          />
          <ImportantInformation
            data={require('../data/pages/brokers.json')}
            sectionOneColummClasses="col-10 offset-1"
          />
        </Box>
      </main>
    </Layout>
  );
};

const StyledFeaturesSlider = styled(FeaturesSlider)`
  div[class*='FeaturesSliderstyled__CardIcon-'] {
    width: 100px !important;
    height: 100px !important;
    @media (min-width: ${BREAKPOINT.LG}) {
      width: 128px !important;
      height: 128px !important;
    }
  }
  li {
    padding-bottom: 12px;
  }
`;

export default Brokers;
